<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="E-Posta Doğrula" :array="[{islink:true,url:'/',text:'Ana Sayfa'},{islink:false,url:'',text:'E-Posta Doğrula'}]"/>
    </section>
    <!--/Breadcrumb-->

    <!--Forgot password-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-md-6 d-block mx-auto">
            <div class="card mb-0">
              <div class="card-header">
                <h3 class="card-title">E-Posta Doğrula</h3>
              </div>
              <div class="card-body" v-if="loaded" style="text-align: center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Forgot password-->
  </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  metaInfo() {
    return {
      title: "E-Posta Doğrula - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "E-Posta Doğrula - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "E-Posta Doğrula - Terapi Vitrini",
      "E-Posta Doğrula - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
    let pageInfo = {
      code: this.$route.params.code,
    };
    this.$store.dispatch("emailCheckControl", pageInfo).then((value) => {
      if (value.type == "success") {
        this.$vToastify.success("E-Posta Doğrulandı", "Başarılı!");
        this.$router.push("/login");
      }
      if (value.type == "error") {
        this.$vToastify.error("Kod Geçerli Değil", "Dikkat!");
        this.$router.push("/home");
      }
    });
  },
  data() {
    return {
      pageGet: [],
      loaded: true,
      email: "",
    };
  },
  components: { Breadcrumb },
  mounted() {
    this.backToTop();
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
};
</script>